import React, { useContext, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { PDApiContext } from '../common/pd_utils';

interface ParcelData {
    ID: number;
    PARCEL: string;
    NAME: string;
    CADASTRE: string;
    TOWN: string;
    CODE: string;
    COUNTY: string;
    PARISH: string;
    TOTAL_AREA: number;
    FOREST_LAND_AREA: number;
    AGRICULT_AREA: number;
    owners?: {
        owner_code: string | null;
        owner_share: string;
    }[];
    offerdata: {
        records: {
            quarter: number;
            district: number;
            area: number;
            volume: number;
            volume_ha: number;
            total_clear_cut_amount: number;
            total_no_cut_amount: number;
            total_land_amount: number;
            last_inventory_year: number;
            species_data: {
                name: string;
                cross_section: number;
                height: number;
                tree_count: number;
                volume_ha?: number;
                volume?: number;
                diameter: number;
                age: number;
                clear_cut_amount: number;
                no_cut_amount: number;
            }[];
        }[];
        totals: {
            total_clear_cut_amount: number;
            total_clear_cut_volume: number;
            total_no_cut_amount: number;
            total_no_cut_volume: number;
            total_land_area: number;
            total_land_amount: number;
        };
    };
}

const ParcelSearch: React.FC = () => {
    const pdApi = useContext(PDApiContext);
    const [parcelRegNumber, setParcelRegNumber] = useState('');
    const [parcel, setParcel] = useState<ParcelData | null>(null);

    const handleSearch = async () => {
        try {
            const parcelResponse = await axios.post<ParcelData>(
                `/floater/main/?action=find_parcel&token=${await pdApi.getSignedToken()}`,
                {
                    parcel_id: parcelRegNumber
                }
            );
            if (parcelResponse.data) {
                setParcel(parcelResponse.data);
            } else {
                setParcel(null);
            }
        } catch (error) {
            let err;
            if (error instanceof AxiosError) {
                if (error.response) err = error.response.data;
                else err = error.message;
            } else {
                err = (error as Error).message;
            }
            console.error('Error fetching data:', err);
            pdApi.showSnackBar(err);
            setParcel(null);
        }
    };

    const formatNumber = (num: number): string => {
        let n = Math.round(num * 10) / 10;
        return new Intl.NumberFormat().format(n);
    };

    const redirectToContact = async (e: React.MouseEvent<HTMLElement>) => {
        const code = e.currentTarget.getAttribute('data-code');
        if (!code) {
            return;
        }
        try {
            const parcelResponse = await axios.post(
                `/person/find?token=${await pdApi.getSignedToken()}`,
                {
                    code
                }
            );

            console.log(parcelResponse.data);
            if (parcelResponse.data) {
                await pdApi.redirectToPerson(parcelResponse.data.id);
            } else {
                await pdApi.showSnackBar(`Could not find person in Pipedrive`);
            }
        } catch (error) {
            await pdApi.showSnackBar(
                `Error finding person
                ${(error as Error).message}`
            );
        }
    };

    const minInventoryYear = (offerdata: ParcelData['offerdata']): number => {
        const minYear = offerdata.records.reduce(
            (min, record) =>
                record.last_inventory_year < min
                    ? record.last_inventory_year
                    : min,
            Infinity
        );
        return minYear;
    };

    return (
        <div className="tab-pane fade active show scrollable-pane">
            <div className="input-group mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter parcel registration number"
                    value={parcelRegNumber}
                    onChange={(e) => setParcelRegNumber(e.target.value)}
                />
                <div className="input-group-append">
                    <button className="btn btn-primary" onClick={handleSearch}>
                        Search
                    </button>
                </div>
            </div>
            {parcel && (
                <div>
                    <h5>Parcel Details</h5>
                    <table className="table table-striped table-sm fs-7">
                        <tbody>
                            <tr style={{ display: 'none' }}>
                                <th>ID</th>
                                <td>{parcel.ID}</td>
                            </tr>
                            <tr>
                                <th>Parcel Number</th>
                                <td>{parcel.PARCEL}</td>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <td>{parcel.NAME}</td>
                            </tr>
                            <tr>
                                <th>Cadastre</th>
                                <td>{parcel.CADASTRE}</td>
                            </tr>
                            <tr>
                                <th>Town</th>
                                <td>{parcel.TOWN}</td>
                            </tr>
                            <tr>
                                <th>Owners</th>
                                <td>
                                    {parcel.owners?.map((entry, index) => (
                                        <>
                                            <span className="badge rounded-pill text-bg-secondary p-1">
                                                <a
                                                    className="text-bg-secondary"
                                                    key={index}
                                                    href="#"
                                                    data-code={entry.owner_code}
                                                    onClick={redirectToContact}
                                                >
                                                    {entry.owner_code}{' '}
                                                    {entry.owner_share}
                                                </a>
                                            </span>
                                            {'  '}
                                        </>
                                    ))}
                                </td>
                            </tr>
                            <tr>
                                <th>County</th>
                                <td>{parcel.COUNTY}</td>
                            </tr>
                            <tr>
                                <th>Parish</th>
                                <td>{parcel.PARISH}</td>
                            </tr>
                            <tr>
                                <th>Total Area</th>
                                <td>{parcel.TOTAL_AREA}</td>
                            </tr>
                            <tr>
                                <th>Forest Land Area</th>
                                <td>{parcel.FOREST_LAND_AREA}</td>
                            </tr>
                            <tr>
                                <th>Agricultural Area</th>
                                <td>{parcel.AGRICULT_AREA}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            {parcel?.offerdata && (
                <div>
                    <h5>Offer</h5>
                    <p className="alert alert-light">
                        <span className="badge rounded-pill text-bg-success fs-6">
                            €
                            {formatNumber(
                                parcel.offerdata.totals.total_clear_cut_amount +
                                    parcel.offerdata.totals
                                        .total_no_cut_amount +
                                    parcel.offerdata.totals.total_land_amount
                            )}{' '}
                            {formatNumber(
                                parcel.offerdata.totals.total_clear_cut_volume +
                                    parcel.offerdata.totals.total_no_cut_volume
                            )}
                            m<sup>3</sup>{' '}
                            {formatNumber(
                                parcel.offerdata.totals.total_land_area
                            )}
                            ha
                        </span>
                        <br />
                        CC: €
                        {formatNumber(
                            parcel.offerdata.totals.total_clear_cut_amount
                        )}{' '}
                        {formatNumber(
                            parcel.offerdata.totals.total_clear_cut_volume
                        )}
                        m<sup>3</sup>
                        <br />
                        NC: €
                        {formatNumber(
                            parcel.offerdata.totals.total_no_cut_amount
                        )}{' '}
                        {formatNumber(
                            parcel.offerdata.totals.total_no_cut_volume
                        )}
                        m<sup>3</sup>
                        <br />
                        L: €
                        {formatNumber(
                            parcel.offerdata.totals.total_land_amount
                        )}{' '}
                        {formatNumber(parcel.offerdata.totals.total_land_area)}
                        ha
                        <br />
                        Last Inventory:
                        <span
                            className={
                                new Date().getFullYear() -
                                    minInventoryYear(parcel.offerdata) >
                                5
                                    ? 'badge text-bg-warning'
                                    : ''
                            }
                        >
                            {minInventoryYear(parcel.offerdata)}
                        </span>
                    </p>
                    {parcel.offerdata.records.map((entry, index) => (
                        <div key={index} className="mb-4">
                            <p className="fs-6">
                                Q: {entry.quarter} D: {entry.district} A:{' '}
                                {entry.area}ha V: {entry.volume}m<sup>3</sup>{' '}
                                V/ha: {entry.volume_ha}m<sup>3</sup> CC: €
                                {formatNumber(entry.total_clear_cut_amount)} NC:
                                €{formatNumber(entry.total_no_cut_amount)} L: €
                                {formatNumber(entry.total_land_amount)}
                            </p>
                            <table className="table table-striped table-sm fs-7">
                                <thead>
                                    <tr>
                                        <th>Specie</th>
                                        <th>Age</th>
                                        <th>Height m</th>
                                        <th>Diameter cm</th>
                                        <th>
                                            X Section m<sup>2</sup>/ha
                                        </th>
                                        <th>
                                            Vol/ha m<sup>3</sup>
                                        </th>
                                        <th>
                                            Vol m<sup>3</sup>
                                        </th>
                                        <th> CC €</th>
                                        <th> NC €</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entry.species_data.map((species, idx) => (
                                        <tr key={idx}>
                                            <td>{species.name}</td>
                                            <td>{species.age}</td>
                                            <td>{species.height}</td>
                                            <td>{species.diameter}</td>
                                            <td>{species.cross_section}</td>
                                            <td>
                                                {species.volume_ha ?? (
                                                    <span className="badge text-bg-warning">
                                                        NaN
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                {species.volume ?? (
                                                    <span className="badge text-bg-warning">
                                                        NaN
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                {formatNumber(
                                                    species.clear_cut_amount
                                                ) ?? (
                                                    <span className="badge text-bg-warning">
                                                        NaN
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                {formatNumber(
                                                    species.no_cut_amount
                                                ) ?? (
                                                    <span className="badge text-bg-warning">
                                                        NaN
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
export default ParcelSearch;
