import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

interface ParcelData {
    offerdata: {
        records: {
            quarter: number;
            district: number;
            area: number;
            volume: number;
            volume_ha: number;
            total_clear_cut_amount: number;
            total_no_cut_amount: number;
            total_land_amount: number;
            last_inventory_year: number;
            species_data: {
                name: string;
                cross_section: number;
                height: number;
                tree_count: number;
                volume_ha?: number;
                volume?: number;
                diameter: number;
                age: number;
                clear_cut_amount: number;
                no_cut_amount: number;
            }[];
        }[];
        totals: {
            total_clear_cut_amount: number;
            total_clear_cut_volume: number;
            total_no_cut_amount: number;
            total_no_cut_volume: number;
            total_land_area: number;
            total_land_amount: number;
        };
    };
}

const ParcelSearch: React.FC = () => {
    const [parcelRegNumber, setParcelRegNumber] = useState('');
    const [parcel, setParcel] = useState<ParcelData | null>(null);
    const navigate = useNavigate();

    const handleSearch = async () => {
        try {
            const parcelResponse = await axios.post<ParcelData>(`/search`, {
                parcel_id: parcelRegNumber
            });
            if (parcelResponse.data) {
                setParcel(parcelResponse.data);
            } else {
                setParcel(null);
            }
        } catch (error) {
            let err;
            if (error instanceof AxiosError) {
                if (error.response) err = error.response.data;
                else err = error.message;
                if (error.response?.status === 401) {
                    navigate('/sapp/login');
                    return;
                }
            } else {
                err = (error as Error).message;
            }
            console.error('Error fetching data:', err);
            setParcel(null);
        }
    };

    const formatNumber = (num: number): string => {
        let n = Math.round(num * 10) / 10;
        return new Intl.NumberFormat().format(n);
    };

    const minInventoryYear = (offerdata: ParcelData['offerdata']): number => {
        const minYear = offerdata.records.reduce(
            (min, record) =>
                record.last_inventory_year < min
                    ? record.last_inventory_year
                    : min,
            Infinity
        );
        return minYear;
    };

    return (
        <div>
            <div className="container w-25 container-left">
                <div className="input-group mb-3 mt-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter parcel registration number"
                        value={parcelRegNumber}
                        onChange={(e) =>
                            setParcelRegNumber(e.target.value.trim())
                        }
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-primary"
                            onClick={handleSearch}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="container container-left">
                {parcel &&
                    (!parcel?.offerdata ||
                        parcel?.offerdata?.records?.length === 0) && (
                        <div>Parcel not found</div>
                    )}
                {parcel?.offerdata &&
                    parcel?.offerdata?.records?.length > 0 && (
                        <div>
                            <h5>Offer</h5>
                            <p className="alert alert-light">
                                <span className="badge rounded-pill text-bg-success fs-6">
                                    €
                                    {formatNumber(
                                        parcel.offerdata.totals
                                            .total_clear_cut_amount +
                                            parcel.offerdata.totals
                                                .total_no_cut_amount +
                                            parcel.offerdata.totals
                                                .total_land_amount
                                    )}{' '}
                                    {formatNumber(
                                        parcel.offerdata.totals
                                            .total_clear_cut_volume +
                                            parcel.offerdata.totals
                                                .total_no_cut_volume
                                    )}
                                    m<sup>3</sup>{' '}
                                    {formatNumber(
                                        parcel.offerdata.totals.total_land_area
                                    )}
                                    ha
                                </span>
                                <br />
                                CC: €
                                {formatNumber(
                                    parcel.offerdata.totals
                                        .total_clear_cut_amount
                                )}{' '}
                                {formatNumber(
                                    parcel.offerdata.totals
                                        .total_clear_cut_volume
                                )}
                                m<sup>3</sup>
                                <br />
                                NC: €
                                {formatNumber(
                                    parcel.offerdata.totals.total_no_cut_amount
                                )}{' '}
                                {formatNumber(
                                    parcel.offerdata.totals.total_no_cut_volume
                                )}
                                m<sup>3</sup>
                                <br />
                                L: €
                                {formatNumber(
                                    parcel.offerdata.totals.total_land_amount
                                )}{' '}
                                {formatNumber(
                                    parcel.offerdata.totals.total_land_area
                                )}
                                ha
                                <br />
                                Last Inventory:
                                <span
                                    className={
                                        new Date().getFullYear() -
                                            minInventoryYear(parcel.offerdata) >
                                        5
                                            ? 'badge text-bg-warning'
                                            : ''
                                    }
                                >
                                    {minInventoryYear(parcel.offerdata)}
                                </span>
                            </p>
                            {parcel.offerdata.records.map((entry, index) => (
                                <div key={index} className="mb-4">
                                    <p className="fs-6">
                                        Q: {entry.quarter} D: {entry.district}{' '}
                                        A: {entry.area}ha V: {entry.volume}m
                                        <sup>3</sup> V/ha: {entry.volume_ha}m
                                        <sup>3</sup> CC: €
                                        {formatNumber(
                                            entry.total_clear_cut_amount
                                        )}{' '}
                                        NC: €
                                        {formatNumber(
                                            entry.total_no_cut_amount
                                        )}{' '}
                                        L: €
                                        {formatNumber(entry.total_land_amount)}
                                    </p>
                                    <table className="table table-striped table-sm fs-7">
                                        <thead>
                                            <tr>
                                                <th>Specie</th>
                                                <th>Age</th>
                                                <th>Height m</th>
                                                <th>Diameter cm</th>
                                                <th>
                                                    X Section m<sup>2</sup>/ha
                                                </th>
                                                <th>
                                                    Vol/ha m<sup>3</sup>
                                                </th>
                                                <th>
                                                    Vol m<sup>3</sup>
                                                </th>
                                                <th> CC €</th>
                                                <th> NC €</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {entry.species_data.map(
                                                (species, idx) => (
                                                    <tr key={idx}>
                                                        <td>{species.name}</td>
                                                        <td>{species.age}</td>
                                                        <td>
                                                            {species.height}
                                                        </td>
                                                        <td>
                                                            {species.diameter}
                                                        </td>
                                                        <td>
                                                            {
                                                                species.cross_section
                                                            }
                                                        </td>
                                                        <td>
                                                            {species.volume_ha ?? (
                                                                <span className="badge text-bg-warning">
                                                                    NaN
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {species.volume ?? (
                                                                <span className="badge text-bg-warning">
                                                                    NaN
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {formatNumber(
                                                                species.clear_cut_amount
                                                            ) ?? (
                                                                <span className="badge text-bg-warning">
                                                                    NaN
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {formatNumber(
                                                                species.no_cut_amount
                                                            ) ?? (
                                                                <span className="badge text-bg-warning">
                                                                    NaN
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </div>
    );
};
export default ParcelSearch;
