import React, { useContext, useEffect, useState } from 'react';
import TabView from './components/TabView';
import { PDApiContext } from './common/pd_utils';
import { useNavigate } from 'react-router-dom';

const App: React.FC = () => {
    const pdApi = useContext(PDApiContext);
    const [isInitialized, setIsInitialized] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        try {
            pdApi.initialize();
            setIsInitialized(true);
        } catch (error) {
            console.error('Error initializing:', error);
            navigate('/');
        }
    }, []);

    if (!isInitialized) {
        return null;
    }

    return (
        <div className="container-fluid">
            <TabView />
        </div>
    );
};

export default App;
