import React from 'react';
import StandaloneParcelSearch from './components/StandaloneParcelSearch';

const App: React.FC = () => {
    return (
        <div className="container-fluid">
            <StandaloneParcelSearch />
        </div>
    );
};

export default App;
