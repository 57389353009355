import { ReactNode, useState, useEffect, StrictMode } from 'react';
import './styles/main.scss';
import App from './App';
import SearchApp from './SearchApp';
import Login from './components/Login';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(
        null
    );

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get('/auth/check');
                setIsAuthenticated(true);
            } catch (error) {
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return null;
    }

    if (!isAuthenticated) {
        return <Navigate to="/sapp/login" />;
    }

    return <>{children}</>;
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/sapp/login" element={<Login />} />
                <Route
                    path="/sapp"
                    element={
                        <ProtectedRoute>
                            <SearchApp />
                        </ProtectedRoute>
                    }
                />
                <Route path="/app" element={<App />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    </StrictMode>
);
