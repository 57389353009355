import React, { useState } from 'react';
import ParcelSearch from './ParcelSearch';
import NextPersonToCall from './NextPersonToCall';

const TabView: React.FC = () => {
    const [activeTab, setActiveTab] = useState('nextCall');

    return (
        <div>
            <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                    <button
                        className={`nav-link ${
                            activeTab === 'nextCall' ? 'active' : ''
                        }`}
                        onClick={() => setActiveTab('nextCall')}
                    >
                        Next Call
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${
                            activeTab === 'parcelSearch' ? 'active' : ''
                        }`}
                        onClick={() => setActiveTab('parcelSearch')}
                    >
                        Parcel Search
                    </button>
                </li>
            </ul>

            <div className="tab-content">
                {activeTab === 'parcelSearch' && <ParcelSearch />}
                {activeTab === 'nextCall' && <NextPersonToCall />}
            </div>
        </div>
    );
};

export default TabView;
