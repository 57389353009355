import { createContext } from 'react';
import AppExtensionsSDK, { Command, View } from '@pipedrive/app-extensions-sdk';

class PDAppExtensionsSDK {
    _sdk: AppExtensionsSDK | undefined;

    initialize() {
        if (!this._sdk) {
            this._sdk = new AppExtensionsSDK();
            this._sdk
                .initialize({
                    size: { height: 600, width: 700 }
                })
                .catch((e) => {
                    console.error('Error initializing AppExtensionsSDK:', e);
                    throw e;
                })
                .then(() => {});
        }
    }

    public get sdk() {
        return this._sdk;
    }
    async showSnackBar(message: string) {
        return this._sdk?.execute(Command.SHOW_SNACKBAR, {
            message
        });
    }

    async redirectToPerson(id: string) {
        return this._sdk?.execute(Command.REDIRECT_TO, {
            view: View.CONTACTS,
            id: id
        });
    }

    async getSignedToken() {
        const token: { token: string } | undefined =
            this._sdk && (await this._sdk.execute(Command.GET_SIGNED_TOKEN));

        return token ? token.token : undefined;
    }

    async resizeWindow(_width: number, height: number) {
        const height_px = (60 * height) / 100;
        await this._sdk?.execute(Command.RESIZE, { height: height_px });
    }
}

export const PDApiContext = createContext(new PDAppExtensionsSDK());
