import React, { useContext, useState } from 'react';
import axios from 'axios';
import { PDApiContext } from '../common/pd_utils';

const NextPersonToCall: React.FC = () => {
    const pdApi = useContext(PDApiContext);
    const [loading, setLoading] = useState(false);

    const findNextPerson = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `/floater/main?token=${await pdApi.getSignedToken()}&action=find_contact`
            );
            await pdApi.redirectToPerson(response.data.id);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                await pdApi.showSnackBar(error.response.data);
            } else {
                console.error('Error fetching next person to call:', error);
                await pdApi.showSnackBar((error as Error).message);
            }
        }
        setLoading(false);
    };
    return (
        <div className="tab-pane fade active show scrollable-pane">
            <button
                className="btn btn-primary mb-3"
                onClick={findNextPerson}
                type="button"
                disabled={loading}
            >
                <span
                    className={`${
                        loading ? 'spinner-border spinner-border-sm' : ''
                    }`}
                    aria-hidden="true"
                ></span>
                <span role="status">Find Next Person</span>
            </button>
        </div>
    );
};

export default NextPersonToCall;
