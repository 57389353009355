import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await axios.post(
                '/auth/login',
                {
                    username,
                    password
                },
                {
                    withCredentials: true
                }
            );
            navigate('/sapp');
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <div className="container-fluid vh-100">
            <div className="row h-100 align-items-center justify-content-center">
                <div className="col-auto">
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            size={30}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                        />
                    </div>
                    <div className="input-group mb-3">
                        <input
                            type="password"
                            size={30}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                        />
                    </div>
                    <div className="input-group mb-3 justify-content-center">
                        <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                        >
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
